import React from 'react';
import { graphql } from 'gatsby';

import { helmetPageValues as hel } from '../utils/constants';
import { Layout, ContactLayout } from '../layouts';
import { GridText, Helmet } from '../components';
import GridSubMain from '../components/shared/GridSubMain';
import GridSubOne from '../components/shared/GridSubOne';
import GridSubTwo from '../components/shared/GridSubTwo';

const paragraph = `Fast development of most modern websites built in the latest 
  technology that will provide search engine optimization out of the box and allow 
  your business to be visible. Our websites are fast loading, cross-platform solutions 
  that will give your business quick start
`;

const subtitle = `Digital house of your business that we will be the architects of`;

const Websites = ({ data: { fluid: { childImageSharp } }}) => {
  return (
    <Layout>
      <Helmet {...hel.websites} />
      <ContactLayout page="" >
        <GridSubMain columns="3fr 5fr">
          <GridSubOne image={childImageSharp.fluid} />
          <GridSubTwo>
            <GridText
              isInverse
              heading="Websites"
              nodes={['React', 'Vue', 'JavaScript']}
              paragraph={paragraph}
              subtitle={subtitle}
            />
          </GridSubTwo>
        </GridSubMain>
      </ContactLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    fluid: file(relativePath: {eq: "websites.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Websites;
